<template>
  <div v-loading="pageloading">
    <notopen>
      <div class = "flexCol Allthe"> 
        <!-- <el-card style="height:110px" body-style = "padding:0px"> -->
          <div class="content" :style="{'background-color':'white'}">
            <div class="left">
              <img src="https://cdn.dkycn.cn/melyshop/image/20210831-9a54f3fa-4a97-4df5-80f8-0c82ceaa0d56.png">
              <div class="info">
                <div class="tit">企微涨粉</div>
                <div class="tip">在该功能模块下，您可以将员工活码投放至：商品详情页或客户订单支付成功时。客户在浏览商品或成功购买商品后，引导客户主动添加员工企业微信。支持给客户打上标签、发送指定欢迎语等</div>
              </div>
            </div>
            <div class="right">
              <qr-codepop :downloadData="experienceCode" :buttontype="'primary'" trigger="click" :icon="false" :downFont="'功能体验码'"></qr-codepop>
            </div>
          </div>
        <!-- </el-card> -->
        <el-card class = "cardbody" style="margin-top:10px;flex:1">
          <div class = "flexRow" style="justify-content:space-between;height:100%">
            <div class ="graybox flexCol">
              <div class = "flexRow"  style="justify-content: space-between;">
                <div class = "flexRow">
                  <div class = "fans-titfont">商详页让利涨粉</div>
                  <div class = "vertical"></div>
                  <el-button v-if="getspecific(1).IsOpen" type="text" style="cursor:default">已开启</el-button>
                  <el-button v-else type="text" style="color:#909399;cursor:default">未开启</el-button>
                </div>
                <div class="flexRow">
                  <el-button type="primary" plain size="mini" @click="jumpedit(getspecific(1),1)">编辑</el-button>
                  <el-button v-if="getspecific(1).QyWeixinContactMeId" type="primary" plain size="mini" @click="jumpdata(getspecific(1),1)">数据</el-button>
                  <el-button v-if="getspecific(1).IsOpen" type="danger" plain size="mini" @click="closeconfig(getspecific(1))">关闭</el-button>
                </div>
              </div>
              <div class = "tit-synopsis">
                开启商品详情页的涨粉入口，可配置添加好友后，客户可获得的权益；当客户浏览商品时，吸引客户添加员工企微好友，成为企业微信客户
              </div>
              <div class = "flexRow" style="flex:1;align-items:center">
                <!-- <img :src="imgApi+'/image/risefansleft.png'" style="width:100%" alt=""> -->
                <img src="https://cdn.dkycn.cn/melyshop/image/20221114-e68c7790-78ee-4ab8-af7d-94d8accc6a97.png" style="width:100%" alt="">
              </div>
            </div>
            <div class ="graybox flexCol">
              <div class = "flexRow"  style="justify-content: space-between;">
                <div class = "flexRow">
                  <div class = "fans-titfont">支付成功引导锁粉</div>
                  <div class = "vertical"></div>
                  <el-button v-if="getspecific(2).IsOpen" type="text" style="cursor:default">已开启</el-button>
                  <el-button v-else type="text" style="color:#909399;cursor:default">未开启</el-button>
                </div>
                <div class="flexRow">
                  <el-button type="primary" plain size="mini" @click="jumpedit(getspecific(2),2)">编辑</el-button>
                  <el-button v-if="getspecific(2).QyWeixinContactMeId" type="primary" plain size="mini" @click="jumpdata(getspecific(2),2)">数据</el-button>
                  <el-button v-if="getspecific(2).IsOpen" type="danger" plain size="mini" @click="closeconfig(getspecific(2))">关闭</el-button>
                </div>
              </div>
              <div class = "tit-synopsis">
                开启支付成功后引导客户添加员工企业微信，你可以配置添加员工企业微信后发送优惠券等，促进客户二次消费
              </div>
              <div class = "flexRow" style="flex:1;align-items:center">
                <!-- <img :src="imgApi+'/image/risefansright.png'" style="width:100%" alt=""> -->
                <img src="https://cdn.dkycn.cn/melyshop/image/20221114-99c7f508-dd18-4326-b771-4d65a4020017.png" style="width:100%" alt="">
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </notopen>
  </div>
</template>

<script>
import{
  qyWeixinIncreaseFanslist,
  qyWeixinIncreaseFansclose
}from "@/api/sv1.0.0"
import QrCodepop from "@/components/bathQrCodes2"
import notopen from "./components/notopen"
import config from "@/config"
export default {
  components:{
    notopen,
    QrCodepop
  },
  data () {
    return {
      imgApi:config.IMG_BASE,
      pageloading:false,
      experienceCode:{
        url: 'https://cdn.dkycn.cn/melyshop/image/qiweizhangfen.png',
				icon: '',
				name: '',
        link:'https://jusnn6k8al.feishu.cn/docs/doccn034RSVU0WSY8hxTmG7e7ib',
      },
      message:[],
    }
  },
  created () {
    this.getrisefanslist()
  },
  methods: {
    //关闭
    closeconfig(e){
      this. toclose(e)
    },
    async toclose(e){
      // console.log(e)
      this.pageloading=true
      try{
        let id = await e.Id
        let data = {
          Id:id
        }
        // console.log(data)
        let result = await qyWeixinIncreaseFansclose(data)
        if(result.IsSuccess){
          this.$message.success('操作成功')
          this.getrisefanslist()
        }
      }finally{
        this.pageloading=false
      }
    },
    async getrisefanslist(){
      this.pageloading = true
      try{
        let res = await qyWeixinIncreaseFanslist()
        if(res.IsSuccess){
          this.message = res.Result
          // console.log(this.message)
        }
      }finally{
        this.pageloading = false
      }
    },
    getspecific(num){
      let a = this.message.find((v)=>{
        return v.PageType == num
      })
      // console.log(a)
      return a?a:{}
    },
    jumpdata(msg,num){
      // console.log(msg.QyWeixinContactMeId)
      if(!msg.PageType){
        this.$message.error('该配置未开启')
      }else{
        let router = '/qyCustomer/callmedata?Id='+msg.QyWeixinContactMeId+'&type='+num
        this.$router.push({
          path:router
        })
      }
    },
    jumpedit(msg,num){
        let router = ''
        if(num==1){
          router = "/qyCustomer/risefans/risefansdetail"
        }else if(num==2){
          router = "/qyCustomer/risefans/risefanssuccess"
        }
        this.$router.push({
          path:router
        })
    },
  }

}
</script>

<style lang = "less" scoped>
.content {
		display: flex;
		align-items: center;
		overflow: hidden;
		padding: 0px 15px;
    height: 80px;
		background-color: #f8f8f9;
		
		.left {
			flex: 1 1 auto;
			overflow: hidden;
			margin-right: 20px;
			display: flex;
	
			img {
				flex: 0 0 auto;
				width: 46px;
				height: 46px;
			}
	
			.info {
				flex: 1 1 auto;
				overflow: hidden;
				margin-left: 10px;
				padding-top: 2px;
	
				.tit {
					font-size: 14px;
					font-weight: bold;
				}
	
				.tip {
					font-size: 12px;
					color: #999;
					margin-top: 10px;
				}
			}
		}
	
		.right {
			flex: 0 0 auto;
		}
	}


.Allthe{
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.flexRow{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}
.flexCol{
  display: flex;
  flex-direction: column;
}
.graybox{
  border:1px solid #d5d5d6;
  width: 48%;
  height:100%;
  padding: 10px 15px;
  .fans-titfont{
    font-weight: bold;
    font-size: 15px;
    font-family: "PingFang SC" !important;
  }
  .vertical{
    height:20px;
    width: 1px;
    background: #d5d5d6;
    margin:0px 10px
  }
  .tit-synopsis{
    font-size: 14px;
    line-height:1.5;
    color: #606266;
    margin:10px 0px
  }
}
.cardbody ::v-deep .el-card__body{
  height: 100%;
}
</style>