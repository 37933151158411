<template>
  <div v-loading = "pageloading">
    <div v-if="isAuth">
      <slot></slot>
    </div>
    <el-card v-else>
      <div class="content">
				<div class="left">
					<img src="https://cdn.dkycn.cn/melyshop/image/20210802-c8b01cda-ac9f-464c-ab7c-73bb1d848b9d.png">
					<div class="info">
						<div class="tit">企微涨粉</div>
						<div class="tip">在该功能模块下，您可以将员工活码投放至：商品详情页或客户订单支付成功时。客户在浏览商品或成功购买商品后，引导客户主动添加员工企业微信。支持给客户打上标签、发送指定欢迎语等</div>
					</div>
				</div>
				<div class="right">
					<el-button type="primary" @click="handleUse">授权安装企店助手</el-button>
				</div>
			</div>
      <div style="margin-top:25px">
        <div>
          <div class="desc-title">1/入口一：商详页让利涨粉</div>
          <img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210928-a32fa0d6-a1d4-4218-85bc-088e8a891229.png" />
        	<div class="desc-title">2/入口二：商城装修投放涨粉入口</div>
          <img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210928-5bd6e029-cd00-4360-af73-c7e0ef3af6fe.png" />
        	<div class="desc-title">3/入口三：支付成功引导涨粉入口</div>
          <img style="display: block;width:100%;max-width: 885px;" src="https://cdn.dkycn.cn/melyshop/image/20210928-edda06f0-d957-4346-b793-2285fac47c9c.png" />
        </div>
			</div>
    </el-card>
  </div>
</template>

<script>
import {
  qyweixinsuiteauthinfo
}from "@/api/sv1.0.0"
import QrCodepop from "@/components/bathQrCodes2"
export default {
  components:{
    QrCodepop
  },
  data () {
    return {
// 			https://cdn.dkycn.cn/melyshop/image/20210928-46ca872c-e3fc-4fc7-aca5-1bb29d97d669.png
// https://cdn.dkycn.cn/melyshop/image/20210928-5bd6e029-cd00-4360-af73-c7e0ef3af6fe.png
// https://cdn.dkycn.cn/melyshop/image/20210928-edda06f0-d957-4346-b793-2285fac47c9c.png
      pageloading:false,
      isAuth:true,
    }
  },
  created () {
    this.getData()
  },
  methods: {
    async getData(callback) {
				try {
					const res = await qyweixinsuiteauthinfo();
					this.isAuth = res.Result.IsFinishQyWeixinSuiteStp;
				} catch (e) {
					console.log(e)
				}
			},
    handleUse(){
      this.$router.push({
				path: '/saleChannel/qyWechat'
			})
    },
    
  }
}
</script>

<style lang = "less" scoped>
.content {
		display: flex;
		align-items: center;
		overflow: hidden;
		padding: 0px 15px;
		height: 80px;
		background-color: #f8f8f9;
		
		.left {
			flex: 1 1 auto;
			overflow: hidden;
			margin-right: 20px;
			display: flex;
	
			img {
				flex: 0 0 auto;
				width: 46px;
				height: 46px;
			}
	
			.info {
				flex: 1 1 auto;
				overflow: hidden;
				margin-left: 10px;
				padding-top: 2px;
	
				.tit {
					font-size: 14px;
					font-weight: bold;
				}
	
				.tip {
					font-size: 12px;
					color: #999;
					margin-top: 10px;
				}
			}
		}
	
		.right {
			flex: 0 0 auto;
		}
	}

.flexRow{
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
}
.graybox{
  border:1px solid #d5d5d6;
  width: 48%;
}

.desc-title{
		font-weight: bold;
		margin-top: 30px;
		font-size: 16px;
		margin-bottom: 10px;
	}
	.sec-title{
		font-size: 14px;
		color: #909399;
		margin-top:30px
	}
	.desc-content{
		font-size: 14px;
		color: #606266;
		line-height: 20px;
		
		>div{
			margin-bottom: 5px;
		}
	}

</style>